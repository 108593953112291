import React from 'react';
import SkillsData from '../assets/files/skills.json';
import Layout from '../components/layout';

import SkillSection from '../components/sections/SkillsSection/SkillsSection';

const Skills = () => (
    <Layout pageTitle="My skills">
        <SkillSection data={SkillsData} />
    </Layout>
);

export default Skills;
import React from 'react';
import "./Skill.scss";

const Skill = ({ title, items }) => (
    <section className="skillsWrapper__section">
        <h3 className="skillsWrapper__section--title">...{title}</h3>
        {items ?
            <ul className="skillsWrapper__list">
                {items.map(item => <li key={item} className={`skillsWrapper__list--item ${title.replace(' ', '')}`}>{item}</li>)}
            </ul>
            :
            <p>Loading data..</p>
        }
    </section>
);

export default Skill;
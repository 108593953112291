import React from 'react';

import Skill from '../../Skill/Skill';

import './SkillsSection.scss';


const SkillSection = ({ data }) => (
    <section className="skillsWrapper">
        <h2 className="skillsWrapper__subTitle">What I</h2>
        {data.length ?
            (data.map(({ title, content }) =>
                <Skill key={title} title={title} items={content} />
            ))
            :
            <p>Loading...</p>
        }
    </section>
);

export default SkillSection;